import React, { useState } from "react";

import { addDoc, collection } from "firebase/firestore";
import { firestore } from "../../firebase";
import { Button } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";

import { setAdminShowJob, setShowForm } from "../../redux/uiSlice";
import { updateJob } from "../../redux/jobsSlice";

const ClickToFillForm = () => {
  const dispatch = useDispatch();

  const selectedJob = useSelector((state) => state.allJobs.selectedJob);

  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    address: "",
    businessName: "",
    businessLocation: "",
    products: "",
    quantity: "",
    message: "",
  });

  const bulkInquiryForm = collection(firestore, "clickForm");

  let name, value;
  const postUserData = (event) => {
    name = event.target.name;
    value = event.target.value;

    if (name === "keySkills") {
      setFormData({ ...formData, [name]: value.split(",") });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const styleObj = {
    border: "2px solid #ced4da",
    borderRadius: "2px",
    outline: "none",
    fontSize: "15px",
  };

  const submitData = async (event) => {
    event.preventDefault();

    if (true) {
      addDoc(bulkInquiryForm, formData).then(() => {
        setShowForm(false);
        alert("You will Receieve Phone Call from our team.");
        setFormData({
          jobId: "",
          image: "",
          location: "",
          title: "",
          subTitle: "",
          totalExperience: "",
          relevantExperience: "",
          salary: "",
          city: "",
          state: "",
          postedAt: "",
          openings: "",
          applicants: "",
          jobDescription: "",
          requiredSkills: "",
          roleName: "",
          industryType: "",
          department: "",
          employmentType: "",
          roleCategory: "",
          education: "",
          keySkills: "",
        });

        dispatch(setAdminShowJob({ window: false, form: false }));
      });
    } else {
      // dispatch(updateJob({ jobId: selectedJob.id, newJobData: formData }));
      // dispatch(setAdminShowJob({ window: false, form: false }));
    }
  };

  return (
    <>
      {/* Start Single Timeline  */}

      <div className="row row--30 align-items-center">
        <div className="order-1 p-sm-5  col-lg-12 ">
          <div className="thumbnail ">
            <div className="rtl">
              <div
                className="card"
                style={{ maxHeight: "550px", overflowY: "scroll" }}
              >
                <div className="card-body">
                  <div style={{ minHeight: "20px" }}></div>
                  <div className="d-flex justify-content-between">
                    <h4 className="card-title d-flex justify-content-center">
                      Fill The Form
                    </h4>

                    <Button
                      onClick={() => dispatch(setShowForm(false))}
                      style={{ fontSize: "15px", backgroundColor: "red" }}
                    >
                      X
                    </Button>
                  </div>

                  <div style={{ minHeight: "20px" }}></div>
                  <form onSubmit={submitData}>
                    <div className="row mt-4">
                      <div className="col-md-6 ">
                        <label htmlFor="validationCustom01">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={formData.name}
                          onChange={postUserData}
                          style={styleObj}
                          required
                          // placeholder={adminShowJob.job?.location}
                          // defaultValue={adminShowJob.job?.location}
                        />
                      </div>
                      <div className="col-md-6 ">
                        <label htmlFor="validationCustom04">Phone Number</label>
                        <input
                          type="text"
                          className="form-control"
                          name="phoneNumber"
                          value={formData.phoneNumber}
                          onChange={postUserData}
                          style={styleObj}
                          required
                          // placeholder={adminShowJob.job?.title}
                          // defaultValue={adminShowJob.job?.title}
                        />
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-md-6 ">
                        <label htmlFor="validationCustom01">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          value={formData.email}
                          onChange={postUserData}
                          style={styleObj}
                          required
                          // placeholder={adminShowJob.job?.subTitle}
                          // defaultValue={adminShowJob.job?.subTitle}
                        />
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-md-6">
                        <label htmlFor="validationCustom04">Address</label>
                        <input
                          type="text"
                          className="form-control"
                          name="address"
                          value={formData.adress}
                          onChange={postUserData}
                          style={styleObj}
                          required
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-6">
                        <label htmlFor="validationCustom04">
                          Business Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="businessName"
                          value={formData.businessName}
                          onChange={postUserData}
                          style={styleObj}
                          required
                          // placeholder={adminShowJob.job?.city}
                          // defaultValue={adminShowJob.job?.city}
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="validationCustom04">
                          Business Location
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="businessLocation"
                          value={formData.businessLocation}
                          onChange={postUserData}
                          style={styleObj}
                          required
                          // placeholder={adminShowJob.job?.state}
                          // defaultValue={adminShowJob.job?.state}
                        />
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-md-6 ">
                        <label htmlFor="validationCustom01">Products</label>
                        <input
                          type="text"
                          className="form-control"
                          name="products"
                          value={formData.products}
                          onChange={postUserData}
                          style={styleObj}
                          required
                          // placeholder={adminShowJob.job?.openings}
                          // defaultValue={adminShowJob.job?.openings}
                        />
                      </div>
                      <div className="col-md-6 ">
                        <label htmlFor="validationCustom04">Quantity</label>
                        <input
                          type="text"
                          className="form-control"
                          name="applicants"
                          value={formData.quantity}
                          onChange={postUserData}
                          style={styleObj}
                          required
                          // placeholder={adminShowJob.job?.applicants}
                          // defaultValue={adminShowJob.job?.applicants}
                        />
                      </div>
                    </div>
                    <div style={{ minHeight: "50px" }}></div>
                    <div className="row mt-4">
                      <div className="col-md-12 ">
                        <label htmlFor="validationCustom01">
                          Drop a Message
                        </label>
                        <textarea
                          className="form-control"
                          style={{
                            fontSize: "15px",
                            height: "100px",
                            border: "2px solid #ced4da",
                            borderRadius: "2px",
                            outline: "none",
                          }}
                          name="message"
                          value={formData.message}
                          onChange={postUserData}
                          required
                          // placeholder={adminShowJob.job?.jobDescription}
                          // defaultValue={adminShowJob.job?.jobDescription}
                        />
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-md-12">
                        <div class="d-grid gap-2">
                          <button class="btn btn-primary btn-lg" type="submit">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* End Single Timeline  */}
    </>
  );
};

export default ClickToFillForm;
