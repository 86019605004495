import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setCurrentPage } from "../../redux/pageSlice";
import {
  FiBookOpen,
  FiBriefcase,
  FiCoffee,
  FiLifeBuoy,
  FiPlayCircle,
  FiTool,
} from "react-icons/fi";

const Nav = () => {
  const textColor = "#001532";
  const dispatch = useDispatch();
  return (
    <ul className="mainmenu">
      <li className="has-droupdown">
        <Link style={{ color: textColor }} to="#">
          Products
        </Link>
        <ul className="submenu">
          <li
            onClick={() => {
              dispatch(setCurrentPage("trolley-carts-stand"));
            }}
          >
            <Link
              style={{ color: textColor }}
              to="/products/trolley-carts-stand"
            >
              {"Trolley Carts (Stand)"}
            </Link>
          </li>
          <li
            onClick={() => {
              dispatch(setCurrentPage("wall-mounts"));
            }}
          >
            <Link style={{ color: textColor }} to="/products/wall-mounts">
              Wall Mounts
            </Link>
          </li>
          <li
            onClick={() => {
              dispatch(setCurrentPage("video-wall"));
            }}
          >
            <Link style={{ color: textColor }} to="/products/video-wall">
              Video Wall
            </Link>
          </li>
          <li
            onClick={() => {
              dispatch(setCurrentPage("monitor-arms"));
            }}
          >
            <Link style={{ color: textColor }} to="/products/monitor-arms">
              Monitor Arms
            </Link>
          </li>
        </ul>
      </li>
      <li className="has-droupdown">
        <Link style={{ color: textColor }} to="#">
          Solutions
        </Link>
        <ul className="submenu">
          <li
            onClick={() => {
              dispatch(setCurrentPage("tv-stands-carts-trolley"));
            }}
          >
            <Link style={{ color: textColor }} to="#">
              <FiBookOpen style={{ color: textColor }} /> Education
            </Link>
          </li>
          <li>
            <Link style={{ color: textColor }} to="#">
              <FiBriefcase style={{ color: textColor }} /> Business
            </Link>
          </li>
          <li>
            <Link style={{ color: textColor }} to="#">
              <FiTool style={{ color: textColor }} /> Custom
            </Link>
          </li>
          <li>
            <Link style={{ color: textColor }} to="#">
              <FiLifeBuoy style={{ color: textColor }} /> Health Care
            </Link>
          </li>
          <li>
            <Link style={{ color: textColor }} to="#">
              <FiPlayCircle style={{ color: textColor }} /> Entertainment
            </Link>
          </li>
          <li>
            <Link style={{ color: textColor }} to="#">
              <FiCoffee style={{ color: textColor }} /> Hospitality
            </Link>
          </li>
        </ul>
      </li>

      <li className="has-droupdown">
        <Link style={{ color: textColor }} to="#">
          About Us
        </Link>
        <ul className="submenu">
          <li>
            <Link style={{ color: textColor }} to="/company">
              Company
            </Link>
          </li>
          <li>
            <Link style={{ color: textColor }} to="#">
              Blogs
            </Link>
          </li>
          <li>
            <Link style={{ color: textColor }} to="#">
              Exhibition News
            </Link>
          </li>
          {/* <li>
            <Link style={{ color: textColor }} to="#">
              Careers
            </Link>
          </li> */}
        </ul>
      </li>
    </ul>
  );
};
export default Nav;
