import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../elements/logo/Logo";
import footerOne from "../../data/footer/footerOne.json";
import ScrollTop from "./ScrollTop";
import { collection } from "firebase/firestore";
import { setShowForm } from "../../redux/uiSlice";
import { firestore } from "../../firebase";
import config from "../../config";
import { useDispatch } from "react-redux";
const footerIntem = footerOne[0];
const footerIntemOne = footerOne[1];
const footerIntemTwo = footerOne[2];
const footerIntemThree = footerOne[3];
const footerIntemFour = footerOne[4];
const footerIntemFive = footerOne[5];
const indexOneLink = footerIntemOne.quicklink;
const indexTwoLink = footerIntemTwo.quicklink;
const indexThreeLink = footerIntemThree.quicklink;
const indexFourLink = footerIntemFour.quicklink;

const FooterOne = () => {
  const [result, showresult] = useState(false);
  const dispatch = useDispatch();
  const [contactData, setContactData] = useState({
    email: "",
  });
  let name, value;
  const postUserData = (event) => {
    name = event.target.name;
    value = event.target.value;

    setContactData({ ...contactData, [name]: value });
  };
  const newsLetterRequests = collection(firestore, "newsLetterRequests");
  const submitData = async () => {
    // event.preventDefault();
    const { email } = contactData;

    if (email) {
      const res = fetch(config.NEWS_LETTER_API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
        }),
      });

      if (res) {
        setContactData({
          email: "",
        });

        alert("Hurrah! You Subscribed");
      } else {
        alert("plz fill the data");
      }
    } else {
      alert("plz fill the data");
    }
  };

  return (
    <>
      <footer className="rn-footer footer-style-default">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              {/* Start Single Widget  */}

              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="rn-footer-widget">
                  <div className="widget-menu-top">
                    <h4 className="title">Pixel Mount</h4>
                    <div className="inner">
                      <p>
                        Pixel Mounts is a leading provider of cutting-edge
                        mounting solutions designed to meet the diverse needs of
                        both residential and commercial environments. Our
                        product range includes TV trolley carts, wall mount
                        brackets, video walls, and monitor arms, each crafted
                        with precision and durability in mind.
                      </p>
                      <p>
                        Address - Unit 111, Veena Industrial Estate, LBS Marg,
                        Opposite Everest Masala, Vikhroli West,Mumbai Suburban
                        400083,Maharashtra,India
                      </p>

                      <a
                        href="gs://react-website-ba066.appspot.com/New Pixel Mount Product Catalog Booklet.pdf"
                        className="btn-default btn-small btn-border"
                        download
                      >
                        Download Brochure
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Single Widget  */}

              <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                <div className="rn-footer-widget">
                  <div className="widget-menu-top">
                    <h4 className="title">{footerIntemThree.title}</h4>
                    <div className="inner">
                      <ul className="footer-link link-hover">
                        {indexThreeLink.map((data, index) => (
                          <li key={index}>
                            <Link to={`${data.url}`}>{data.text}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Single Widget  */}

              {/* Start Single Widget  */}
              <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                <div className="rn-footer-widget">
                  <div className="widget-menu-top">
                    <h4 className="title">{footerIntemOne.title}</h4>
                    <div className="inner">
                      <ul className="footer-link link-hover">
                        {indexOneLink.map((data, index) => (
                          <li key={index}>
                            <Link to={`${data.url}`}>{data.text}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Single Widget  */}

              {/* Start Single Widget  */}
              <div className="sm-1 col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="inner">
                  <ul className="footer-link link-hover">
                    {indexFourLink.map((data, index) => (
                      <h4 key={index}>
                        <Link style={{ fontSize: "18px" }} to={`${data.url}`}>
                          {data.text}
                        </Link>
                      </h4>
                    ))}
                  </ul>
                </div>

                <div className="rn-footer-widget  pl--10">
                  <div className="logo ">
                    <Logo
                      image={`${process.env.REACT_APP_PUBLIC_URL}/images/logo/stemmOnec2.png`}
                      image2={`${process.env.REACT_APP_PUBLIC_URL}/images/logo/stemmOnec2.png`}
                    />
                  </div>
                  <div className=" rn-footer-widget pt--10">
                    <h4 className="title">{footerIntemFive.title}</h4>
                    <div className="inner">
                      {/* <h6 className="subtitle">{footerIntemFive.subtitle}</h6> */}

                      {/* <div className="form-group">
                          <input
                            type="email"
                            placeholder="Enter Your Email Here"
                            name="email"
                            value={contactData.email}
                            onChange={postUserData}
                            required
                          />
                        </div> */}

                      <button
                        onClick={() => dispatch(setShowForm(true))}
                        className="btn-default"
                      >
                        Click to fill the form
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Single Widget  */}
            </div>
          </div>
        </div>
      </footer>
      <ScrollTop />
    </>
  );
};

export default FooterOne;
