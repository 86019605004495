import React from "react";
import { lazy, Suspense } from "react";
import { useRoutes } from "react-router-dom";
import Error from "../pages/Error";
import ComingSoon from "../pages/ComingSoon";
const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );

const ServicesPage = Loader(lazy(() => import("../pages/ServicesPage")));
const ProductDetails = Loader(lazy(() => import("../pages/ProductDetails")));
const ProductCategoryPage = Loader(
  lazy(() => import("../pages/ProductCategoryPage"))
);
const Home = Loader(lazy(() => import("../pages/Home")));
const Careers = Loader(lazy(() => import("../pages/Careers")));
const Contact = Loader(lazy(() => import("../pages/Contact")));

const AboutUs = Loader(lazy(() => import("../pages/AboutUs")));
function Router() {
  let element = useRoutes([
    {
      path: `${"/"}`,
      element: <Home />,
    },
    {
      path: `${"/company"}`,
      element: <Careers />,
    },
    {
      path: `${"/contact"}`,
      element: <Contact />,
    },

    {
      path: `${"/coming-soon"}`,
      element: <ComingSoon />,
    },

    {
      path: `${"/about"}`,
      element: <AboutUs />,
    },
    {
      path: `${"/products/:pageNameParams"}`,
      element: <ProductCategoryPage />,
    },
    {
      path: `${"/products/:pageNameParams/:productID"}`,
      element: <ProductDetails />,
    },
    {
      path: `${process.env.REACT_APP_PUBLIC_URL + "/services/:pageNameParams"}`,
      element: <ServicesPage />,
    },
    { path: "*", element: <Error /> },
  ]);

  return element;
}

export default Router;
