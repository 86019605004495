import Modal from "react-bootstrap/Modal";

import { useSelector, useDispatch } from "react-redux";
import { setAdminShowJob, setShowForm } from "../../redux/uiSlice";
import ClickToFill from "./ClickToFillForm";
import { setSelectedJob } from "../../redux/jobsSlice";

function ClickFormModal() {
  const dispatch = useDispatch();
  const showForm = useSelector((state) => state.ui.showForm);
  return (
    <>
      <Modal
        closeButton
        size="md"
        show={showForm}
        // fullscreen={true}
        onHide={() => {
          dispatch(setShowForm(false));
        }}
      >
        <ClickToFill />
      </Modal>
    </>
  );
}

export default ClickFormModal;
