// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore, doc, setDoc } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDLUANWQuykWUgKdwR_mne3KXLUVL2Uh5w",
  authDomain: "react-website-ba066.firebaseapp.com",
  databaseURL: "https://react-website-ba066-default-rtdb.firebaseio.com",
  projectId: "react-website-ba066",
  storageBucket: "react-website-ba066.appspot.com",
  messagingSenderId: "931071068458",
  appId: "1:931071068458:web:beadc79ecd62dbea09b653",
  measurementId: "G-P0Q5FQ2R7E",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const firestore = getFirestore(app); // Export firestore

const specialofTheDay = doc(firestore, "dailySpecial/2024-01-17");

function writeDailySpecial() {
  const docData = {
    description: "A vanilla cake",
    price: 8.99,
    milk: "soy",
    vegan: "true",
    descrwwiption: "A vanilla cake",
    pwwrice: 8.99,
    mwwilk: "soy",
    vwwegan: "true",
  };

  setDoc(specialofTheDay, docData, { merge: true });
}

writeDailySpecial();
