import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import PageScrollTop from "./components/pageToTop/PageScrollTop";
import Router from "./router/Routing";
import "./assets/scss/style.scss";
import { Suspense } from "react";
import InstaStoryModal from "./elements/stories/InstaStoryModal";

import JobAddModal from "./elements/jobAdd/JobAddModal";
import ClickFormModal from "./elements/showClickFormModal/ClickFormModal";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );

const App = () => {
  useEffect(() => {
    document.querySelector("body").classList.add("light");
  }, []);

  return (
    <>
      <BrowserRouter>
        <ClickFormModal />
        {/* <InstaStoryModal /> */}
        {/* <JobAddModal /> */}
        <PageScrollTop>
          <Router />
        </PageScrollTop>
      </BrowserRouter>
    </>
  );
};

export default App;
